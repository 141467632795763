:root {
  --margin-x: 16px; /* if you change this you must change the next two lines */
  --jobs-post-container-max-width: 1400px; /* if you change this you must change; @media (max-width: 1432px) { */
  --jobs-filter-container-max-width: 1024px; /* if you change this you must change; @media (max-width: 1056px) { */
  --mobile-breakpoint: 1024px;

  --listing-panel-height: 3500px;
  --listing-gap: 36px;

  --aj-orange: hsl(29, 100%, 50%);
  --box-shadow-color: rgb(0 0 0 / 0.1);
  --box-shadow: 0 10px 15px -3px var(--box-shadow-color),
    0 4px 6px -4px var(--box-shadow-color);
}

html {
  body,
  body .prose {
    .profile_grid {
      grid-template-columns: repeat(16, 1fr);
      grid-template-areas: "main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content";
      /* grid-template-areas: "side_nav side_nav side_nav main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content main_content"; */
      width: 100%;
      margin: auto;
      gap: 30px;
      padding: 0;
      margin-top: 50px;
    }
    .profile .icon {
      width: 24px;
    }
    h2.profile {
      font-size: 30px;
      color: var(--steal-blue);
      line-height: 38px;
    }

    .side_nav {
      grid-area: side_nav;
    }
    .main_content {
      grid-area: main_content;
    }

    .jobs_panel {
      grid-area: jobs_panel;
    }

    .dashboard_menu {
      padding: 8px;
    }

    .persons_name {
      color: var(--steal-blue);
    }

    .title_dashboard_context {
      color: var(--steal-blue);
      text-align: center;
      /* background-color: rgb(241 245 249); */
      padding: 24px 16px;
      font-weight: bold;
      border: 1px solid rgb(255, 140, 0);
      /* border-bottom: 1px solid #e0e0e0; */
      border-radius: 16px;
    }
    .dashboard_button.active {
      background-color: #fdf3fd;
      font-weight: bold;
      /* color: rgb(255, 106, 0); */
    }
    @media (max-width: 1432px) {
           .profile_grid.side_nav_container,
           .profile_grid.job_post_header_container {
             margin-left: var(--margin-x);
             margin-right: var(--margin-x);
           }
         }
         @media (max-width: 1056px) {
           .profile_grid.job_post_search_container,
           .profile_grid.job_post_header_container {
             margin-left: var(--margin-x);
             margin-right: var(--margin-x);
           }
         }

         @media (max-width: 1024px) {
           .profile_grid {
             margin-left: var(--margin-x);
             margin-right: var(--margin-x);
             grid-template-columns: 1fr;
             grid-template-areas:
               'main_content'
               'jobs_panel';
           }

           .side_nav_container {
             grid-template-areas:
               'side_nav'
               'main_content';
           }

           .job_post_search_container {
             grid-template-areas:
               'jobs_panel'
               'main_content';
           }
           .job_post_header_container {
             grid-template-areas:
               'location_panel'
               'applications_close_panel';
           }
         }

         .contact-card {
          width: 250px; 
          background-color: white;
          padding: 16px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
        }
        
        .card {
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 16px;
          display: flex;
          align-items: center;
          flex-direction: row; /* Image on the left, text on the right */
        }
        
        .image-container {
          flex-shrink: 0; /* Prevent the image from shrinking */
          width: 176px; /* Adjust this width as needed */
          height: auto;
          overflow: hidden; /* Hide any overflowing parts of the image */
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .image-container img {
          width: 100%;
          height: auto; /* Maintain aspect ratio */
          object-fit: cover; /* Ensure the image covers the container without being cut off */
        }
        
        .text-container {
          flex-grow: 1; /* Take up remaining space */
          display: flex;
          flex-direction: column; /* Stack the header above the paragraph */
          justify-content: center; /* Vertically center the text */
        }
        
        h5 {
          margin-bottom: 8px; /* Space between header and paragraph */
        }
        
  }
}
