@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./css/grid-layout.css";
@import "./css/aspa-theme.css";
@import "./css/profile.css";

html {
  scroll-behavior: smooth !important;
}
body {
  font-family: "Open Sans", sans-serif;
}
:root {
  --color-primary: #e74b7f;
  --color-primary-hover: #de6c92;
  --color-primary-light: #de6c92;
  --steal-blue: rgb(59, 86, 131);
}
#root {
  margin-left: auto;
  margin-right: auto;
  /*max-width: 1280px;/
    /* overflow: hidden; */
}
.dark {
  @apply bg-gray-800 text-red-600;
}
ul {
  list-style-type: none;
}
label {
  font-size: 1rem;
}
.grid1 {
  columns: 4;
  column-gap: 0;
}
.break {
  break-before: column;
}
.wrapper p {
  margin: 10px 0 10px 0;
}
.wrapper h2 {
  font-size: 26px;
  font-weight: bold;
}
.wrapper h3 {
  font-size: 24px;
  font-weight: bold;
}
iframe {
  width: 100%;
}
.academic-award {
  font-weight: bold;
}
.academic-award {
  width: 50px;
}
.nav-link {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  height: 60px;
  color: #000;
  padding: 18px 8px;
  margin: 0px 14px;
  display: inline-block;
  position: relative;
  /* opacity: 0.75; */
}
.nav-link:hover {
  opacity: 1;
}
.nav-link::before,
.nav-link::after {
  position: absolute;
  height: 48px;
  transition: 300ms;
  content: "";
  border-bottom: 3px solid var(--color-primary);
  z-index: -1;
  left: 0;
  width: 0%;
  bottom: 6px;
}
.nav-link-ltr::before {
  width: 0%;
}
.nav-link::after {
  position: absolute;
  transition: 300ms;
  content: "";
  border-bottom: 3px solid #ddd;
  z-index: -2;
  width: 100%;
}
.nav-link-ltr:hover::before {
  background-color: var(--color-primary-light);
  width: 100%;
}
.nav-link-ltr.active::before {
  width: 100%;
  border-bottom: 3px solid black;
}
.nav-link.active {
  color: var(--color-primary);
}
.active {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.post-a-job {
  background-color: var(--color-primary-light);
}
.mobile-icons {
  font-size: 20px;
  color: var(--color-primary-light);
}
.mobile-icons.triangle {
  font-size: 20px;
}
.nav-sub-heading {
  /* font-size: 9px; */
  line-height: 0.7;
  font-weight: bold;
}
input {
  position: relative;
}
i.required {
  display: none;
}
p[required] {
  margin-left: 10px;
  font-size: 12px;
  margin-top: 6px;
  font-style: italic;
}
input[required] + i.required,
select[required] + i.required,
textarea[required] + i.required,
p[required]::before {
  display: block;
  color: red;
  position: absolute;
  inset: 0;
  left: 6px;
  top: 0px;
  font-style: normal;
  font-size: 20px;
  width: 10px;
  height: 20px;
}
p[required]::before {
  content: "*";
  left: 0px;
  top: -2px;
}
.asterisk {
  color: red;
  position: relative;
  top: 8px;
  left: 1px;
  font-style: normal;
  font-size: 20px;
  line-height: 0;
}
section h2,
section h3,
section h4 {
  color: var(--color-primary);
  margin-top: 1.5rem;
}
section h2 {
  font-size: 1.8rem;
}
section h3 {
  font-size: 1.4rem;
}
section h4 {
  font-size: 1.1rem;
  color: #757575;
  font-weight: bold;
}
section p {
  font-size: 1rem;
  margin-bottom: 8px;
  line-height: 1.4;
}
section.template a {
  color: rgb(0, 107, 213);
  text-decoration: underline;
  font-weight: bold;
  font-weight: 600;
}
section.template .school-images {
  display: flex;
}
section.template .school-images img {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
section.template .school-images img.school-logo {
  max-width: 100px;
}
section.template .nomination-badge {
  max-width: 140px;
  height: auto;
  margin-top: 20px;
}
/* Email Template styles */
section.email-template .header {
  font-size: 24px;
  font-weight: bold;
}
section.email-template .sub-header {
  font-size: 18px;
  font-style: italic;
}
/* Style for principal's name */
section.email-template .principal-name {
  font-size: 20px;
  font-weight: bold;
}
/* Style for paragraph text */
section.email-template p {
  font-size: 16px;
  line-height: 1.5;
}
/* Style for links */
section.email-template a {
  color: #007bff;
  text-decoration: none;
}
/* Style for digital nomination badge */
section.email-template .nomination-badge {
  max-width: 100px;
}
/* Style the unordered list */
/* section.template ul,
section ul,
.ul {
  list-style-type: disc;
  padding-left: 40px;
} */
/* Style the list items */
/* section.template li,
section li,
.ul li {
  margin-bottom: 8px;
} */
.list-headings {
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 16px;
}
.list-headings:first-child {
  margin-top: 0;
}
.talent-pool input,
.talent-pool textarea,
.talent-pool select {
  border: 1px solid #ccc;
}
/* .quill .ql-editor {
  resize: vertical;
  overflow: auto;
} */
.quill {
  height: 100%;
}
.quill .ql-editor {
  resize: vertical;
  overflow: auto;
  min-height: 300px;
  height: 100%;
}
.bg-aj {
  background-color: var(--color-primary);
}
.text-aj {
  color: var(--color-primary);
}
.btn-aj {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 100vmax;
}
.btn-aj:hover {
  background-color: var(--color-primary-hover) !important;
}
input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}
input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}
input[type="radio"]:checked + label span {
  background-color: #1f9d55;
  box-shadow: 0px 0px 0px 2px white inset;
}
input[type="radio"]:checked + label {
  color: #1f9d55;
}
.container {
  margin-left: auto;
  margin-right: auto;
}
h1 {
  font-size: 2em;
  /* margin: 0.67em 0; */
}
.custom-background h1 {
  color: white;
  font-size: 2.5rem;
  line-height: 3.2rem;
}
html body .content-grid > .full-width {
  grid-column: full-width;
  display: grid;
  grid-template-columns: inherit;
}
html body .content-grid {
  --padding-inline: 1rem;
  --content-max-width: 1320px;
  --breakout-max-width: 1200px;
  --breakout-size: calc(
    (var(--breakout-max-width) - var(--content-max-width)) / 2
  );
  display: grid;
  grid-template-columns:
    [ full-width-start] minmax(var(--padding-inline), 1fr)
    [ breakout-start] minmax(0, var(--breakout-size))
    [ content-start] min(
      100% - var(--padding-inline) * 2,
      var(--content-max-width)
    )
    [ content-end] minmax(0, var(--breakout-size)) [ breakout-end] minmax(
      var(--padding-inline),
      1fr
    )
    [ full-width-end];
}

.wrapper {
  position: relative;
  background-color: transparent;
  height: 90%;
  align-items: center;
  padding-left: 0px;
}
.wrapper .sentence {
  color: #ffdb3d;
  font-size: 36px;
  text-align: left;
  font-family: "Noto Sans Lao Looped", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 20px;
}
/*Vertical Sliding*/
.slidingVertical {
  display: inline;
}
.slidingVertical span {
  animation: topToBottom 12.5s linear infinite 0s;
  -ms-animation: topToBottom 12.5s linear infinite 0s;
  -webkit-animation: topToBottom 12.5s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}

.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.slidingVertical span:nth-child(4) {
  animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5) {
  animation-delay: 10s;
  -ms-animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

/*topToBottom Animation*/
@keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -moz-transform: translateY(-50px);
  }

  10% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -moz-transform: translateY(50px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -ms-transform: translateY(-50px);
  }

  10% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -ms-transform: translateY(50px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

html body .gradient-aj,
html body .prose .gradient-aj {
  background: rgb(255, 173, 31);
  background: radial-gradient(
    circle,
    rgb(255, 173, 31) 0%,
    rgb(255, 112, 31) 100%
  );
}

.sign_up_benefits h5 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.sign_up_benefits {
  margin-top: 0; /* Default to 0 for desktop */
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .sign_up_benefits {
    margin-top: 20px;
  }
}

.sign_up_banner {
  height: 160px;
  width: 1000px;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .sign_up_banner {
    height: 100px;
    width: 600px;
  }
}

.image-container {
  width: 400px;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.responsive-img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .sign_up_benefits .flex {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    width: 50%;
    height: auto;
  }

  .responsive-img {
    width: 100%;
    height: auto;
  }
}

.inline_heading {
  font-weight: bold;
}

h1.sign_up {
  color: var(--color-primary);
  font-weight: bold;
  font-size: 3rem;
}

h2.sign_up,
h2.sign_up a,
.profile,
.hero h2 {
  font-size: 30px;
  color: var(--color-gray-blue);
  line-height: 38px;
  font-weight: bold;
}

.invoice p {
  font-size: 14px;
  margin-bottom: 0;
}
.invoice-items tr td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.invoice-items tr:last-child {
  border-bottom: 2px solid #e8e9f2;
}

.cc-icons > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}

.logout:hover {
  color: var(--aj-orange);
  cursor: pointer;
}

.quill,
.job_post_editor.quill {
  position: relative;
  min-height: 800px;
}

.quill .ql-toolbar,
.job_post_editor.quill .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white !important;
}
.quill .ql-container,
.job_post_editor.quill .ql-container {
  z-index: 1;
  /* background-color: white !important; */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-background {
  position: relative;
  min-height: 100px;
  background: linear-gradient(45deg, rgb(255, 255, 255), rgb(231, 75, 127) 50%);
  background-color: sandybrown;
}

.custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.custom-shape-divider-bottom .shape-fill {
  fill: #ffffff;
}
