:root {
  --main-color: #003650;
  --tj-pink: #ff009d;
}

.aspa_theme {
  position: relative;
  z-index: 50;
}

.city-links {
  margin-top: 1rem !important;
}

.aspa_theme .city-links li {
  color: var(--main-color);
}
.aspa_theme .position-links li,
.aspa_theme .city-links li {
  margin: 0;
  padding: 0;
  line-height: 0.9rem;
}

.aspa_theme .position-links li:hover,
.aspa_theme .city-links li:hover {
  text-decoration: underline;
}

.aspa_theme .hero-icons {
  background-color: transparent;
}
.aspa_theme .hero-icons li {
  max-width: var(--position_type_size);
}
.aspa_theme .hero-icons li img {
  aspect-ratio: 1 / 1;
  background-color: white;
  border-radius: 100vmax;
  border: 1px solid var(--tj-pink);
  /* border-color: pink !important; */
}

.aspa_theme .hero-icons li p {
  margin-top: 8px;
  color: #9da0a8;
}
.aspa_theme .wrapper {
  .sentence {
    &.text-center {
      text-align: center;
    }
    &.text-white {
      color: white;
    }
  }
}

.aspa_theme h2 {
  color: var(--main-color);
  font-family: "montserrat", sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1.4rem;
}
.aspa_theme h2.plain {
  color: black;
  border: none;
  margin-bottom: 1.5rem;
}

.aspa_theme h3.uni_name {
  color: var(--main-color);
}

.aspa_theme .hero-icons {
  background-color: transparent;
}
.aspa_theme .hero-icons li {
  max-width: var(--position_type_size);
}
.aspa_theme .hero-icons li img {
  aspect-ratio: 1 / 1;
  background-color: white;
  border-radius: 100vmax;
}

.aspa_theme .hero-icons li p {
  margin-top: 8px;
  color: #9da0a8;
}
.aspa_theme .wrapper {
  .sentence {
    &.text-center {
      text-align: center;
    }
    &.text-white {
      color: white;
    }
  }
}

.aspa_theme .hero-icons li a p {
  color: var(--clr-primary-500) !important;
  font-weight: 600;
}
.aspa_theme .hero-icons li:hover a p {
  color: var(--color-primary) !important;
}

@media (max-width: 926px) {
  .aspa_theme .hero-icons.hide li:nth-child(5) {
    display: none;
  }
}
@media (max-width: 498px) {
  .aspa_theme .hero-icons.hide li:nth-child(4) {
    display: none;
  }
}

@media (max-width: 300px) {
  .aspa_theme .hero-icons.hide li:nth-child(3) {
    display: none;
  }
  .aspa_theme .word_slider_wrapper {
    font-size: 9px;
    min-width: 250px !important;
    /* margin-bottom: 4rem; */
  }
}

.aspa_theme ul.aj_uni_list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  list-style-type: none;
  gap: 10px;
}

.aspa_theme ul.aj_uni_list li {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 1.5rem;
}

@media (min-width: 768px) {
  .aspa_theme ul.aj_uni_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .aspa_theme ul.aj_uni_list {
    grid-template-columns: repeat(3, 1fr);
  }
}

.aspa_theme .flip-card {
  background-color: transparent;
  width: 100%;
  /* height: 220px; */
  perspective: 1000px;
}

.aspa_theme ul.remove_bullet {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.aspa_theme .flip-card-inner {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.aspa_theme .flip-card:focus {
  outline: 0;
}

.aspa_theme .flip-card:hover .flip-card-inner,
.aspa_theme .flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.aspa_theme .flip-card-front,
.aspa_theme .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
}

.aspa_theme .flip-card-front {
  /* background: linear-gradient(to left, #4364f7, #6fb1fc); */
  background-color: white;
  color: black;
  border: 1px solid rgb(123, 123, 123);

  z-index: 2;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.aspa_theme .flip-card-back {
  /* background: linear-gradient(to right, #4364f7, #6fb1fc); */
  background-color: white;
  border: 1px solid rgb(123, 123, 123);
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
